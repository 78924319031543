import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={process.env.PUBLIC_URL + "/logo.png"} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://radonk.windfx.pl"
          rel="noopener noreferrer"
        >
          RadOnk
        </a>
      </header>
    </div>
  );
}

export default App;
